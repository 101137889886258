<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <div style="display: grid">
      <x-label for="title" tag="label" style="margin-top: 2rem">{{
        $t('form.title')
      }}</x-label>
      <select id="title" v-model="forms.profile.inputField">
        <option v-for="title in titles" :key="title.id" :value="title.id">
          {{ title.title }}
        </option>
      </select>
    </div>
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.profile.inputField"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'

export default {
  name: 'EditProfileTitle',
  components: { AppHeader },
  created() {
    if (this.user) this.forms.profile.inputField = this.user.titleId
  },
  data() {
    return {
      forms: { profile: { inputField: '' } },
      isLoading: false,
    }
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', {
          titleId: this.forms.profile.inputField,
        })
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
  },
  computed: {
    ...mapState('TitleModule', ['titles']),
    ...mapState('UserModule', ['user']),
  },
}
</script>
